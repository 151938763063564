import * as React from 'react';
import { RadioButtonChecked, RestartAlt, StopCircle } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { CrowdScreenState } from '../../sharedTypes';
import { blinkerEffect } from '../CrowdScreen/animation';

const ShowModeController: React.FC<{
  changeShowMode: (mode: CrowdScreenState['showMode']) => void;
  mode: CrowdScreenState['showMode'];
}> = ({ mode, changeShowMode }) => {
  return (
    <>
      <Tooltip
        title={
          <>
            <Typography textAlign='center'>Start</Typography>display Welcome screen.
            <Typography textAlign='center' fontSize='0.5rem'>
              MIDI trigger: C-0
            </Typography>
          </>
        }
      >
        <IconButton
          onClick={() => changeShowMode('before')}
          sx={{ color: mode === 'before' ? 'baseColors.red' : 'baseColors.white' }}
        >
          <RestartAlt />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={
          <>
            <Typography textAlign='center'>Live</Typography>display the Show view
            <Typography textAlign='center' fontSize='0.5rem'>
              MIDI trigger: D-0
            </Typography>
          </>
        }
      >
        <IconButton
          onClick={() => changeShowMode('live')}
          sx={
            mode === 'live'
              ? {
                  color: 'baseColors.red',
                  ...blinkerEffect,
                }
              : { color: 'baseColors.white' }
          }
        >
          <RadioButtonChecked />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={
          <>
            <Typography textAlign='center'>End</Typography>display GoodBye screen
            <Typography textAlign='center' fontSize='0.5rem'>
              MIDI trigger: E-0
            </Typography>
          </>
        }
      >
        <IconButton
          onClick={() => changeShowMode('after')}
          sx={{ color: mode === 'after' ? 'baseColors.red' : 'baseColors.white' }}
        >
          <StopCircle />
        </IconButton>
      </Tooltip>
    </>
  );
};

// the full events:
// { pitch: 12, octave: 0, note: 'C', showMode: 'before' },
// { pitch: 14, octave: 0, note: 'D', showMode: 'live' },
// { pitch: 16, octave: 0, note: 'E', showMode: 'after' },
const MIDI_NOTE_SHOW_MODE: Record<string, CrowdScreenState['showMode']> = Object.freeze({
  C: 'before',
  D: 'live',
  E: 'after',
});

export { ShowModeController, MIDI_NOTE_SHOW_MODE };
