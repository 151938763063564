import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import * as queries from '../../../Queries';
import { ShowContext } from '../../../context/ShowContext';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { UserRoutes } from '../../../routes';
import { logError } from '../../../services/Logger';
import { DeviceMidiEvent } from '../../../services/Midi';
import {
  Scene,
  CreateSceneRequest,
  UpdateSceneRequest,
  MidiEvent,
  Color,
} from '../../../sharedTypes';
import { FAKE_ID_PREFIX } from './utils';

const useSceneEditor = (
  sceneId: string | null,
  colors: Color[],
  title: string,
  midiEvent?: DeviceMidiEvent,
) => {
  const { currentShow } = React.useContext(ShowContext);
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const showId = currentShow?.id || '';

  const onSuccess = () => {
    queryClient.invalidateQueries(queries.SCENE.all(showId).key);
    queryClient.invalidateQueries(queries.SHOW.all.key);
    queryClient.invalidateQueries(queries.SHOW.single(showId).key);
    showSnackbar({ severity: 'success', message: 'Action done successfully' });
    navigate(UserRoutes.BASE);
  };
  const onError = (err: string) => {
    logError(err);
    showSnackbar({ severity: 'error', message: 'Action failed. Please try again later' });
  };

  const {
    data: scene,
    isLoading: isGetSceneLoading,
    error: getSceneError,
  } = useQuery<Scene>(
    queries.SCENE.single(currentShow?.id || '', sceneId || '').key,
    () =>
      axios(queries.SCENE.single(currentShow?.id || '', sceneId || '').url).then((res) => res.data),
    { enabled: Boolean(currentShow?.id && sceneId) },
  );

  const createScene = useMutation(
    (data: CreateSceneRequest) =>
      axios.post<CreateSceneRequest>(queries.SCENE.create.url, { ...data }),
    { onSuccess: () => onSuccess(), onError: (err) => onError(err as string) },
  );

  const updateScene = useMutation(
    (s: Scene) =>
      axios.put(queries.SCENE.update.url(showId), {
        id: s.id,
        title: s.title,
        order: s.order,
        midiEvents: s.midiEvents,
        colors: s.colors,
        showId,
      } as UpdateSceneRequest),
    { onSuccess: () => onSuccess(), onError: (err) => onError(err as string) },
  );

  const saveScene = () => {
    // create
    if (!sceneId || !scene?.id) {
      createScene.mutate({
        showId,
        title,
        midiEvent: midiEvent as CreateSceneRequest['midiEvent'],
        colors: colors.map(({ code, durationPercentage, duration, order }) => ({
          order,
          code,
          durationPercentage,
          duration,
        })),
      });
      return;
    }

    // update
    const midiEv = { ...midiEvent, id: scene.midiEvents[0].id };
    const editedScene: UpdateSceneRequest = {
      id: scene.id,
      title,
      order: scene.order,
      midiEvents: [midiEv] as MidiEvent[],
      colors: colors.map((c) => {
        return c.id?.includes(FAKE_ID_PREFIX) ? { ...c, id: EMPTY_ID } : c;
      }),
      showId,
    };
    updateScene.mutate(editedScene);
  };

  const removeScene = useMutation((id: string) => axios.delete(queries.SCENE.remove.url(id)), {
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries(queries.SCENE.single(currentShow?.id || '', sceneId || '').key);
    },
    onError: (err) => onError(err as string),
  });

  return {
    scene,
    saveScene,
    removeScene,
    isLoading:
      isGetSceneLoading || createScene.isLoading || updateScene.isLoading || removeScene.isLoading,
    getSceneError,
  };
};

// EMPTY_ID matches the DB id format.
const EMPTY_ID = '000000000000000000000000';

export { useSceneEditor };
