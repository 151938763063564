import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import * as queries from '../../Queries';
import { Loader } from '../../components/Loader';
import { PageContainer } from '../../components/PageContainer';
import { PageContent } from '../../components/PageContent';
import { User, UserContext } from '../../context/UserContext';
import { useSnackbar } from '../../hooks/useSnackbar';
import { UserRoutes } from '../../routes';

const Login: React.FC = () => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { setLoggedInUser } = React.useContext(UserContext);
  const [email, setEmail] = React.useState<string | undefined>(undefined);
  const [password, setPassword] = React.useState<string | undefined>(undefined);
  const isEmailErr = !email && email !== undefined;
  const isPasswordErr = !password && password != undefined;

  const { data, isLoading } = useQuery<{ loggedInUser: User }>(
    queries.AUTH.loggedInSessionUser.key,
    () => axios(queries.AUTH.loggedInSessionUser.url).then((res) => res.data || {}),
    {
      onSuccess: (res) => setLoggedInUser(res.loggedInUser),
      // prevent of caching the logged in user,
      // to be able to always have an up to date auth state.
      cacheTime: 0,
    },
  );

  const login = useMutation(
    (creds: { email?: string; password?: string }) => {
      if (!email || !password) {
        return Promise.reject('one or more credentials are missing');
      }
      return axios.put(queries.AUTH.login, creds);
    },
    {
      onSuccess: (res) => {
        setLoggedInUser(res.data);
        navigate(UserRoutes.BASE);
      },
      onError: () => showSnackbar({ severity: 'error', message: 'Login failed. Please try again' }),
    },
  );

  React.useEffect(() => {
    if (data?.loggedInUser) {
      setLoggedInUser(data.loggedInUser);
      navigate(UserRoutes.BASE);
      return;
    }
  }, [data?.loggedInUser, setLoggedInUser, navigate]);

  if (isLoading) {
    return (
      <PageContainer>
        <PageContent>
          <Loader />
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageContent>
        <Stack textAlign='center' alignItems='center' justifyItems='center'>
          <Stack spacing={10} width='20rem'>
            <Typography variant='h2' mb={10}>
              Welcome!
            </Typography>
            <TextField
              type='email'
              label='Email'
              required
              error={isEmailErr}
              helperText={isEmailErr ? 'Email is required' : undefined}
              onChange={(ev) => setEmail(ev.currentTarget.value)}
            />
            <TextField
              type='password'
              label='Password'
              required
              error={isPasswordErr}
              helperText={isPasswordErr ? 'Password is required' : undefined}
              onChange={(ev) => setPassword(ev.currentTarget.value)}
            />
            <Button variant='contained' onClick={() => login.mutate({ email, password })}>
              Login
            </Button>
          </Stack>
        </Stack>
      </PageContent>
    </PageContainer>
  );
};

export { Login };
