import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowBack, BubbleChart } from '@mui/icons-material';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { CrowdScreen } from '../../../components/CrowdScreen';
import { DashboardCard } from '../../../components/DashboardCard';
import { Loader } from '../../../components/Loader';
import { PageContainer } from '../../../components/PageContainer';
import { PageContent } from '../../../components/PageContent';
import { ShowContext } from '../../../context/ShowContext';
import { useMidi } from '../../../hooks/useMidi';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { UserRoutes } from '../../../routes';
import { DeviceMidiEvent } from '../../../services/Midi';
import { Color } from '../../../sharedTypes';
import { EditorPanel } from './EditorPanel';
import { SceneMidiDetails } from './SceneMidiDetails';
import { useSceneEditor } from './useSceneEditor';
import {
  PARAMS_ID,
  calcSceneTempo,
  getRandomColor,
  isValidDeviceMidiEvent,
  recalcColorsDurationAndPercentage,
} from './utils';

const SceneEditor: React.FC = () => {
  const { currentShow } = React.useContext(ShowContext);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { event, device, lockedDevice } = useMidi();
  const [sceneIdParams] = useSearchParams();
  const paramsSceneId = sceneIdParams.get(PARAMS_ID);
  const [colors, setColors] = React.useState<Color[]>([getRandomColor()]);
  const [title, setTitle] = React.useState('');
  const [tempo, setTempo] = React.useState(120);
  const [midiEvent, setMidiEvent] = React.useState<DeviceMidiEvent | undefined>(undefined);
  const [err, setErr] = React.useState<ErrEmptyData>({ title: false, midiEvent: false });
  const [isDialogMidiOpen, setIsDialogMidiOpen] = React.useState(false);
  const { scene, isLoading, getSceneError, saveScene, removeScene } = useSceneEditor(
    paramsSceneId,
    colors,
    title,
    midiEvent,
  );

  const showErrorAndNavigateHome = React.useCallback(
    (error?: string) => {
      showSnackbar({
        severity: 'error',
        message: error || 'something went wrong. Please try again.',
      });
      navigate(UserRoutes.BASE);
    },
    [navigate, showSnackbar],
  );

  // page validaton
  React.useEffect(() => {
    if (!currentShow?.id) {
      showErrorAndNavigateHome();
    }

    // validate edit mode
    const isEditMode = paramsSceneId;
    if (isEditMode) {
      const sceneNotFound = !isLoading && !scene?.id;
      if (sceneNotFound || getSceneError) {
        showErrorAndNavigateHome();
      }
      return;
    }

    // validate create mode
    if (!device?.name && !lockedDevice) {
      showErrorAndNavigateHome('Midi device is not configured. please play some note');
    }
  }, [
    getSceneError,
    isLoading,
    scene,
    currentShow?.id,
    device?.name,
    lockedDevice,
    paramsSceneId,
    showErrorAndNavigateHome,
  ]);

  // init component state with scene values (if exist)
  React.useEffect(() => {
    if (isLoading || !scene?.id) return;
    setTitle(scene.title);
    setTempo(calcSceneTempo(colors));
    setColors(scene.colors);
    setMidiEvent(scene.midiEvents?.[0]);
    // since we don't want to get the origin colors value
    // after adding a new color ->
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene, isLoading]);

  // missing fields detection
  React.useEffect(() => {
    setErr({ title: !title, midiEvent: !midiEvent });
  }, [title, midiEvent]);

  // dialog midi close handling
  React.useEffect(() => {
    if (isDialogMidiOpen && event.timestamp && event.note) {
      const isFreshEvent = event.timestamp > Date.now() - 500;
      if (isFreshEvent) {
        setMidiEvent(event);
        setIsDialogMidiOpen(false);
      }
    }
  }, [event, isDialogMidiOpen]);

  const onOpenMidiDialog = () => {
    if (!device && !lockedDevice) {
      showSnackbar({
        severity: 'error',
        message: 'Please play a MIDI note to select a device',
      });
      navigate(UserRoutes.BASE);
      return;
    }
    setMidiEvent(undefined);
    setIsDialogMidiOpen(true);
  };

  if (paramsSceneId && (isLoading || !scene?.id || !colors.length)) {
    return (
      <PageContainer>
        <PageContent>
          <Loader />
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageContent>
        <Stack alignItems='center' spacing={10}>
          {currentShow?.title && (
            <Typography variant='h4' fontWeight='bold' alignSelf='center'>
              <BubbleChart />
              {capitalize(currentShow.title)}
            </Typography>
          )}
          <DashboardCard
            width='30vw'
            title={
              <TextField
                inputProps={{ sx: { fontWeight: 'bold' } }}
                required
                label='Scene title'
                placeholder='title...'
                value={title}
                variant='standard'
                onChange={({ target: { value } }) => setTitle(value)}
                focused={Boolean(err.title)}
                error={Boolean(err.title)}
                helperText={Boolean(err.title) && 'Scene title is missing'}
              />
            }
            buttons={
              <Box width='5rem'>
                <TextField
                  sx={{ color: 'white' }}
                  inputProps={{ step: '.5', pattern: '[30-300]', sx: { fontWeight: 'bold' } }}
                  required
                  label='Tempo'
                  variant='standard'
                  placeholder='title...'
                  value={tempo}
                  helperText='30-300 bpm'
                  onChange={(e) => {
                    const numberValue = Number(e.target.value);
                    if (Number.isNaN(numberValue)) {
                      return;
                    }
                    setTempo(numberValue);
                  }}
                  onBlur={(e) => {
                    if (tempo < 30 || tempo > 300) {
                      setTempo(120);
                      setColors(recalcColorsDurationAndPercentage(colors, 120));
                      return;
                    }
                    const numberValue = Number(e.target.value);
                    setColors(recalcColorsDurationAndPercentage(colors, numberValue));
                    setTempo(Number(e.target.value));
                  }}
                />
              </Box>
            }
          >
            <CrowdScreen colors={colors} />
            <EditorPanel
              isEditMode={Boolean(paramsSceneId)}
              colors={colors}
              saveScene={() => {
                if (!currentShow?.id) return;
                if (!title) {
                  setErr({ ...err, title: true });
                  return;
                }
                if (!isValidDeviceMidiEvent(midiEvent)) {
                  setErr({ ...err, midiEvent: true });
                  showSnackbar({
                    severity: 'error',
                    message: 'Something went wrong. Try to create another MIDI event',
                  });
                  return;
                }
                saveScene();
              }}
              setColorOrder={setColors}
              removeScene={() => paramsSceneId && removeScene.mutate(paramsSceneId)}
              isMidiDialogOpen={isDialogMidiOpen}
              onMidiClick={onOpenMidiDialog}
              closeMidiDialog={() => setIsDialogMidiOpen(false)}
              midiError={err.midiEvent}
              addColor={() => {
                if (colors.length === 2) {
                  showSnackbar({
                    severity: 'info',
                    message: 'We currently support 2 colors per scene',
                  });
                  return;
                }

                const newColors = [...colors, getRandomColor(colors)];
                setColors(recalcColorsDurationAndPercentage(newColors, tempo));
              }}
              editColorCode={(id, newColorCode) => {
                setColors(
                  colors.map((color) => {
                    if (color.id != id) {
                      return color;
                    }
                    color.code = newColorCode;
                    return color;
                  }),
                );
              }}
              removeColor={(id) => {
                const newColors = [...colors].filter((color) => color.id !== id);
                setColors(recalcColorsDurationAndPercentage(newColors, tempo));
              }}
            />
          </DashboardCard>
          <SceneMidiDetails midiDetails={midiEvent} />
          <Button
            variant='contained'
            component='div'
            sx={{ gap: 2, bgcolor: 'baseColors.grey2' }}
            onClick={() => navigate(UserRoutes.BASE)}
          >
            <ArrowBack />
            Back
          </Button>
        </Stack>
      </PageContent>
    </PageContainer>
  );
};

interface ErrEmptyData {
  title: boolean;
  midiEvent: boolean;
}

export { SceneEditor };
