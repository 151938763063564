const CrowdRoutes = Object.freeze({
  BASE: '/',
});

const UserRoutes = Object.freeze({
  BASE: '/app',
  LOGIN: '/app/login',
  SCENE_EDITOR: {
    base: '/app/scene-editor',
    id: (id: string) => `/app/scene-editor?id=${id}`,
  },
});

export { UserRoutes, CrowdRoutes };
