import React from 'react';
import { CrowdScreen } from '../../../components/CrowdScreen';
import { DashboardCard } from '../../../components/DashboardCard';
import { Color } from '../../../sharedTypes';

const CrowdScreenCard: React.FC<{ colors: Color[] }> = ({ colors }) => {
  return (
    <DashboardCard title='Monitor'>
      <CrowdScreen colors={colors} />
    </DashboardCard>
  );
};

export { CrowdScreenCard };
