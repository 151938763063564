import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { MovieFilter } from '@mui/icons-material';
import { Button, Stack, Tooltip } from '@mui/material';
import { AddNew } from '../../../../components/AddNew';
import { DashboardCard } from '../../../../components/DashboardCard';
import { DialogCreateShow } from '../../../../components/DialogCreateShow';
import { UserRoutes } from '../../../../routes';
import { Scene } from '../../../../sharedTypes';
import { SceneList } from './SceneList';

const ScenesCard: React.FC<ScenesCardProps> = ({ scenes, saveSceneOrder, playScene, saveShow }) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const navigate = useNavigate();

  const onEdit = (id: string) => {
    navigate(UserRoutes.SCENE_EDITOR.id(id));
  };

  const onSaveShow = (title: string) => {
    saveShow(title);
    setIsDialogOpen(false);
  };

  return (
    <DashboardCard
      title='Scenes'
      buttons={
        <Stack direction='row' justifyContent='flex-end'>
          <Tooltip title='Create scene' placement='top'>
            <Button
              sx={{ minWidth: 'unset' }}
              onClick={() => navigate(UserRoutes.SCENE_EDITOR.base)}
            >
              <MovieFilter />
            </Button>
          </Tooltip>
        </Stack>
      }
    >
      <DialogCreateShow
        isOpen={isDialogOpen}
        close={() => setIsDialogOpen(false)}
        save={onSaveShow}
      />
      {!scenes?.length ? (
        <AddNew onClick={() => navigate(UserRoutes.SCENE_EDITOR.base)} />
      ) : (
        <SceneList
          scenes={scenes}
          saveSceneOrder={saveSceneOrder}
          editScene={onEdit}
          playScene={playScene}
        />
      )}
    </DashboardCard>
  );
};

interface ScenesCardProps {
  scenes?: Scene[];
  saveSceneOrder: (scenes: Scene[]) => void;
  playScene: (id: string) => void;
  saveShow: (title: string) => void;
}

export { ScenesCard };
