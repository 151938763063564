import * as React from 'react';
import { ColorLens } from '@mui/icons-material';
import { Box, Button, InputLabel, Tooltip } from '@mui/material';

const ColorButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Box>
    <Tooltip title='Choose colors' placement='top'>
      <InputLabel sx={{ width: '4rem' }}>
        <Button variant='contained' component='div' sx={{ gap: 2 }} onClick={onClick}>
          <ColorLens />
        </Button>
      </InputLabel>
    </Tooltip>
  </Box>
);

export { ColorButton };
