import * as React from 'react';
import { PanToolAlt, Piano } from '@mui/icons-material';
import { Box } from '@mui/material';

const MidiConfigIcons: React.FC = () => (
  <Box display='flex' flexDirection='column' alignItems='center' mt={10}>
    <Piano
      sx={{
        fontSize: '4rem',
        color: 'baseColors.grey2',
        verticalAlign: 'middle',
      }}
    />
    <PanToolAlt
      sx={{
        fontSize: '4rem',
        color: 'baseColors.grey2',
        verticalAlign: 'middle',
      }}
    />
  </Box>
);

export { MidiConfigIcons };
