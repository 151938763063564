import * as React from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Stack } from '@mui/material';

const DragAndDropListContainer = <TlistItem,>({
  saveOrderedItems,
  items,
  children,
}: React.PropsWithChildren<DragAndDropListContainerProps<TlistItem>>) => {
  const orderItemsAndSave = (result: DropResult) => {
    if (!result.destination || !items?.length) return;

    const orderedItems = reorderItems<TlistItem>(
      items,
      result.source.index,
      result.destination.index,
    );
    saveOrderedItems(orderedItems);
  };

  return (
    <DragDropContext onDragEnd={orderItemsAndSave}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <Stack {...provided.droppableProps} ref={provided.innerRef} gap={5}>
            {children}
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const reorderItems = <T,>(items: T[], dragIdx: number, dropIdx: number) => {
  const newItems = [...items];
  const [removedItem] = newItems.splice(dragIdx, 1);
  newItems.splice(dropIdx, 0, removedItem);

  return newItems;
};

interface DragAndDropListContainerProps<TlistItem> {
  saveOrderedItems: (orderedItems: TlistItem[]) => void;
  items: TlistItem[];
}

export { DragAndDropListContainer };
