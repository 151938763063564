import * as React from 'react';
import { Dialog } from '@mui/material';
import { DashboardCard } from '../../../../components/DashboardCard';
import { MidiConfigIcons } from '../../../../components/MidiConfigIcons';

const MidiConfigDialog: React.FC<{ onClose: () => void; isOpen: boolean }> = ({
  onClose,
  isOpen,
}) => (
  <Dialog onClose={onClose} open={isOpen}>
    <DashboardCard title='Play a midi note...'>
      <MidiConfigIcons />
    </DashboardCard>
  </Dialog>
);

export { MidiConfigDialog };
