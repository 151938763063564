import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TipsAndUpdates } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { CrowdRoutes, UserRoutes } from '../../routes';

const AppLogo: React.FC<{ isLoggedInRoute: boolean }> = ({ isLoggedInRoute }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoginPage = location.pathname === UserRoutes.LOGIN;
  const navigateHome = () => {
    !isLoginPage && navigate(isLoggedInRoute ? UserRoutes.BASE : CrowdRoutes.BASE);
  };

  return (
    <>
      <TipsAndUpdates sx={{ cursor: 'pointer', ml: 5 }} onClick={navigateHome} />
      <Typography variant='h6' component='div' sx={{ cursor: 'pointer' }} onClick={navigateHome}>
        SyncLight
      </Typography>
    </>
  );
};

export { AppLogo };
