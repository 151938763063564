import { io, Socket } from 'socket.io-client';

let socket: Socket;

const setup = (onError?: () => void) => {
  const BASE_URL = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:3030';
  socket = io(BASE_URL, {
    reconnection: true,
    autoConnect: true,
    secure: true,
    transports: ['websocket'], // prevent cors
  });

  onError && socket?.io.on('error', onError);
};

const on = <Tdata>(evName: string, cb: (data: Tdata) => void) => socket.on(evName, cb);
const emit = <Tdata>(evName: string, data: Tdata) => socket.emit(evName, data);

// SOCKET EVENTS:
const JOIN_TO_SHOW = 'JOIN_TO_SHOW';
const CHANGE_SCREEN_STATE = 'CHANGE_SCREEN_STATE';
const CHANGE_SHOW_MODE = 'CHANGE_SHOW_MODE';

export { setup, on, emit, socket, JOIN_TO_SHOW, CHANGE_SCREEN_STATE, CHANGE_SHOW_MODE };
