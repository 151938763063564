import * as React from 'react';
import { TipsAndUpdatesOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { animationStyle } from '../../components/CrowdScreen/animation';
import { INIT_SCREEN_STATE } from '../../components/CrowdScreen/consts';
import { Navbar } from '../../components/Navbar';
import { RefreshAlertContext } from '../../context/RefreshAlertContext';
import { useKeepAwake } from '../../hooks/useKeepAwake';
import { useSocket } from '../../hooks/useSocket';
import * as events from '../../services/Socket';
import { CrowdScreenState } from '../../sharedTypes';
import { GoodBye } from './GoodBye';
import { WelcomePage } from './WelcomePage';

const CrowdView: React.FC = () => {
  useKeepAwake();
  const [screenState, setScreenState] = React.useState<CrowdScreenState>(INIT_SCREEN_STATE);
  const { showRefreshAlert } = React.useContext(RefreshAlertContext);
  const { on } = useSocket(showRefreshAlert);
  React.useEffect(() => {
    on<CrowdScreenState>(events.CHANGE_SCREEN_STATE, setScreenState);
  }, [on]);

  return (
    <Box
      minHeight='100vh'
      sx={
        screenState.showMode === 'live'
          ? animationStyle(screenState.colors)
          : {
              // hard coded to be able to match the coldplay logo bgcolor.
              // ToDo => make it black, and add a 'welcome' + 'goodbye' page feature.
              bgcolor: '#1a1a1a',
            }
      }
    >
      <Navbar />
      <Content showMode={screenState.showMode} />
    </Box>
  );
};

const Content: React.FC<{ showMode: CrowdScreenState['showMode'] }> = ({ showMode }) => {
  switch (showMode) {
    case 'before':
      return <WelcomePage />;
    case 'live':
      return (
        <Stack
          sx={{ minHeight: '80vh', widht: '100%', alignItems: 'center', justifyContent: 'center' }}
        >
          <TipsAndUpdatesOutlined sx={{ color: 'black', fontSize: '5rem' }} />
        </Stack>
      );
    case 'after':
      return <GoodBye />;
    default:
      return <WelcomePage />;
  }
};

const crowdScreenDefaultTransitionTime = '0.3s';

export { crowdScreenDefaultTransitionTime, CrowdView };
