import * as React from 'react';
import { AddBox } from '@mui/icons-material';
import { Stack, Tooltip, Button, Typography, Dialog } from '@mui/material';
import { Show } from '../../../sharedTypes';
import { AddNew } from '../../AddNew';
import { AlertDialog } from '../../AlertDialog';
import { DashboardCard } from '../../DashboardCard';
import { DialogCreateShow } from '../../DialogCreateShow';
import { Loader } from '../../Loader';
import { ShowItem } from './ShowItem';

const DialogMyShows: React.FC<DialogMyShowsProps> = ({
  isOpen,
  close,
  shows,
  selectShow,
  createShow,
  removeShow,
  updateShow,
  isLoading,
  error,
}) => {
  const [idToRemove, setIdToRemove] = React.useState<string>('');
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState(false);

  const createNewShow = (title: string) => {
    createShow(title);
    setIsCreateDialogOpen(false);
  };

  const removeSelectedShow = () => {
    if (!idToRemove) return;
    removeShow(idToRemove);
    setIsRemoveDialogOpen(false);
  };

  if (isOpen && isLoading)
    return (
      <Dialog open={isOpen} onClose={close}>
        <Loader />
      </Dialog>
    );

  return (
    <Dialog open={isOpen} onClose={close}>
      <>
        <DialogCreateShow
          isOpen={isCreateDialogOpen}
          close={() => setIsCreateDialogOpen(false)}
          save={createNewShow}
        />
        <AlertDialog
          title='Remove show'
          text='Are you sure you want to remove this show?'
          isOpen={isRemoveDialogOpen}
          onClose={() => {
            setIdToRemove('');
            setIsRemoveDialogOpen(false);
          }}
          actions={
            <Stack direction='row'>
              <Button onClick={() => setIsRemoveDialogOpen(false)}>Cancel</Button>
              <Button onClick={removeSelectedShow} autoFocus>
                Remove
              </Button>
            </Stack>
          }
        />
        <DashboardCard
          title='My shows'
          buttons={
            <Tooltip title='Add a new show' placement='top'>
              <Button sx={{ minWidth: 'unset' }} onClick={() => setIsCreateDialogOpen(true)}>
                <AddBox />
              </Button>
            </Tooltip>
          }
        >
          {/* ToDo: Fix the UI when error */}
          {error && <Typography>Failed to get data. Try to reload the page</Typography>}
          {shows?.length ? (
            shows.map((show) => (
              <ShowItem
                key={show.id}
                show={show}
                selectShow={selectShow}
                onRemove={() => {
                  setIdToRemove(show.id);
                  setIsRemoveDialogOpen(true);
                }}
                editTitle={(newTitle: string) => updateShow({ ...show, title: newTitle })}
              />
            ))
          ) : (
            <AddNew onClick={() => setIsCreateDialogOpen(true)} />
          )}
        </DashboardCard>
      </>
    </Dialog>
  );
};

interface DialogMyShowsProps {
  isOpen: boolean;
  close: () => void;
  shows?: Show[];
  isLoading: boolean;
  error: boolean;
  selectShow: (show: Show) => void;
  createShow: (title: Show['title']) => void;
  removeShow: (id: Show['id']) => void;
  updateShow: (show: Show) => void;
}

export { DialogMyShows };
