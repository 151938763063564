import * as React from 'react';
import * as Router from 'react-router-dom';
import { CrowdView } from './pages/crowd/CrowdView';
import { Login } from './pages/user/Login';
import { SceneEditor } from './pages/user/SceneEditor';
import { UserDashboard } from './pages/user/UserDashboard';
import { CrowdRoutes, UserRoutes } from './routes';

const AppRoutes: React.FC = () => {
  return (
    <Router.Routes>
      <Router.Route path={UserRoutes.LOGIN} element={<Login />} />
      {/* User Routes */}
      <Router.Route path={UserRoutes.BASE} element={<UserDashboard />} />
      <Router.Route path={UserRoutes.SCENE_EDITOR.base} element={<SceneEditor />} />

      {/* Crowd Routes */}
      <Router.Route path={CrowdRoutes.BASE} element={<CrowdView />} />

      {/* Not found */}
      <Router.Route path='*' element={<Router.Navigate to={CrowdRoutes.BASE} />} />
    </Router.Routes>
  );
};

export { AppRoutes };
