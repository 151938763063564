import * as React from 'react';
import { Error, Lock } from '@mui/icons-material';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
  SvgIcon,
} from '@mui/material';
import { DashboardCard } from '../../../components/DashboardCard';
import { MidiConfigIcons } from '../../../components/MidiConfigIcons';
import { DeviceMidiEvent, MidiTarget } from '../../../services/Midi';

const MidiCard: React.FC<React.PropsWithChildren<MidiCardProps>> = ({
  device,
  event,
  lockDevice,
  unlockDevice,
  lockedDevice,
}) => {
  if (!event.note && !lockedDevice) {
    return (
      <DashboardCard title='MIDI'>
        <Stack alignItems='center' spacing={20}>
          <Stack direction='row' alignItems='center' spacing={5}>
            <SvgIcon color='warning' fontSize='small'>
              <Error />
            </SvgIcon>
            <Typography variant='h6'>Play a MIDI note</Typography>
          </Stack>
          <MidiConfigIcons />
        </Stack>
        <Typography fontSize='small'> * Required to access your device</Typography>
      </DashboardCard>
    );
  }
  return (
    <DashboardCard
      title='MIDI'
      buttons={
        <Tooltip
          title={
            device
              ? 'Lock device (Use only this device)'
              : 'To be able to lock device, play some MIDI note'
          }
          placement='top'
        >
          <IconButton
            onClick={lockedDevice ? unlockDevice : lockDevice}
            sx={{ color: lockedDevice ? 'green' : undefined }}
          >
            <Lock />
          </IconButton>
        </Tooltip>
      }
    >
      <Stack flexDirection='column' alignItems='center' spacing={10}>
        <Typography variant='h5' fontWeight='bold'>
          {lockedDevice || device?.name}
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <Cell color='baseColors.grey2'>Note</Cell>
                <Cell color='baseColors.grey2'>Octave</Cell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <Cell>{event?.note || 'N/A'}</Cell>
                <Cell>{event?.octave || (event?.octave === 0 ? 0 : 'N/A')}</Cell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <Cell color='baseColors.grey2'>Type</Cell>
                <Cell color='baseColors.grey2'>Status</Cell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <Cell>{device?.type || 'N/A'}</Cell>
                <Cell>{device?.state || 'N/A'}</Cell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </DashboardCard>
  );
};

const Cell: React.FC<React.PropsWithChildren<{ color?: string }>> = ({ children, color }) => (
  <TableCell
    sx={{
      fontWeight: 'bold',
      textAlign: 'center',
      color,
    }}
  >
    {children}
  </TableCell>
);

interface MidiCardProps {
  device?: MidiTarget;
  event: DeviceMidiEvent;
  lockDevice: () => void;
  unlockDevice: () => void;
  lockedDevice?: string;
}

export { MidiCard };
