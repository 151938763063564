import * as React from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { DragAndDropListContainer } from '../../../../../../components/DragAndDrop/DragAndDropListContainer';
import { DraggableListItemContainer } from '../../../../../../components/DragAndDrop/DraggableListItemContainer';
import { Color } from '../../../../../../sharedTypes';
import { ColorItemButtons } from './ColorItemButtons';

const ColorList: React.FC<SceneListProps> = ({
  colors,
  setColorOrder,
  editColorCode,
  removeColor,
}) => (
  <DragAndDropListContainer saveOrderedItems={setColorOrder} items={colors}>
    {colors.map((color, idx) => {
      return (
        <DraggableListItemContainer key={color.id} draggableId={color.id} itemIndex={idx}>
          <Stack
            justifyContent='space-between'
            flexDirection='row'
            width='100%'
            alignItems='center'
          >
            <Stack spacing={5} direction='row' maxWidth='90%'>
              <Box>
                <Box height='1rem' width='1rem' bgcolor={color.code} display='inline-block' />
              </Box>
              <Tooltip title={color.code} placement='top'>
                <Typography
                  fontWeight='bold'
                  display='inline-block'
                  textOverflow='ellipsis'
                  overflow='auto'
                >
                  {color.code}
                </Typography>
              </Tooltip>
            </Stack>
            <ColorItemButtons
              onChangeColor={(newColor) => editColorCode(color.id, newColor)}
              onRemove={() => removeColor(color.id)}
              isMoreThanOneColor={colors.length > 1}
            />
          </Stack>
        </DraggableListItemContainer>
      );
    })}
  </DragAndDropListContainer>
);

interface SceneListProps {
  colors: Color[];
  setColorOrder: (scene: Color[]) => void;
  editColorCode: (id: string, newColor: string) => void;
  removeColor: (id: string) => void;
}

export { ColorList };
