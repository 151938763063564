import * as React from 'react';
import { Facebook, Instagram, PhoneIphone, WbSunny } from '@mui/icons-material';
import { Box, IconButton, Stack, SvgIcon, Typography } from '@mui/material';

const WelcomePage: React.FC = () => {
  return (
    <Stack justifyContent='center' alignItems='center' textAlign='center' p={5}>
      <Typography
        variant='h3'
        color='white'
        sx={{
          direction: 'rtl',
        }}
      >
        ברוכים הבאים
      </Typography>
      <Typography
        variant='h4'
        color='white'
        mb='1rem'
        sx={{
          direction: 'rtl',
        }}
      >
        למופע המחווה לקולדפליי
      </Typography>
      <Typography
        variant='h6'
        color='white'
        mb='2rem'
        sx={{
          direction: 'rtl',
        }}
      >
        באתר זה תוכלו לחוות את ההופעה בצורה אינטראקטיבית חדשה!
      </Typography>
      <Box
        component='img'
        mb='2rem'
        sx={{
          width: '100%',
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
        }}
        src={require('../../media/coldplay-logo.jpeg')}
      />
      <Box mb='5rem'>
        <TipList />
      </Box>
      <SocialMedia />
    </Stack>
  );
};

const TipList: React.FC = () => {
  return (
    <>
      <Typography
        color='white'
        variant='h6'
        mb={5}
        sx={{
          direction: 'rtl',
          fontWeight: 'bold',
        }}
      >
        הנה כמה טיפים שיוכלו לשפר את החוויה:
      </Typography>
      <Stack spacing={5}>
        <Stack direction='row' flexDirection='row-reverse' gap={5} alignItems='center'>
          <WbSunny sx={{ color: 'baseColors.white' }} />
          <Typography
            color='white'
            sx={{
              direction: 'rtl',
              textAlign: 'start',
            }}
          >
            הגדר את בהירות הטלפון שלך ל-100% לקבלת האפקטים הוויזואליים האופטימליים.
          </Typography>
        </Stack>
        <Stack direction='row' flexDirection='row-reverse' gap={5} alignItems='center'>
          <PhoneIphone sx={{ color: 'baseColors.white' }} />
          <Typography
            color='white'
            sx={{
              direction: 'rtl',
              textAlign: 'start',
            }}
          >
            השאר את המסך מואר לאורך כל ההופעה כדי להישאר תמיד מחובר.
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

const SocialMedia: React.FC = () => {
  return (
    <Stack spacing={5}>
      <Typography
        variant='h6'
        color='white'
        sx={{
          direction: 'rtl',
        }}
      >
        בקרוב יחל הקסם...
      </Typography>
      <Typography
        variant='h6'
        color='white'
        sx={{
          direction: 'rtl',
        }}
      >
        בינתיים מזמינים אתכם לעקוב אחרינו!
      </Typography>
      <Stack direction='row' justifyContent='center' spacing={10}>
        <IconButton>
          <a target='_blank' rel='noreferrer' href='https://www.facebook.com/xandycoldplay/'>
            <SvgIcon sx={{ fontSize: '3rem', color: 'baseColors.white' }}>
              <Facebook />
            </SvgIcon>
          </a>
        </IconButton>
        <IconButton>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://instagram.com/israel_coldplay?igshid=NTc4MTIwNjQ2YQ=='
          >
            <SvgIcon sx={{ fontSize: '3rem', color: 'baseColors.white' }}>
              <Instagram />
            </SvgIcon>
          </a>
        </IconButton>
      </Stack>
    </Stack>
  );
};

export { WelcomePage };
