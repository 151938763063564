import * as React from 'react';
import { useSocket } from '../hooks/useSocket';
import * as events from '../services/Socket';
import { Show, CrowdScreenState } from '../sharedTypes';

const ShowContext = React.createContext<ShowContextContent>({
  currentShow: undefined,
  setCurrentShow: (_show?: Show) => undefined,
  showMode: 'before',
  setShowMode: (_mode: CrowdScreenState['showMode']) => undefined,
});

const ShowProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { emit } = useSocket();
  const [show, setShow] = React.useState<Show | undefined>(undefined);
  const [showMode, setShowMode] = React.useState<CrowdScreenState['showMode']>('before');

  return (
    <ShowContext.Provider
      value={{
        currentShow: show,
        setCurrentShow: setShow,
        showMode,
        setShowMode: (mode) => {
          emit<CrowdScreenState['showMode']>(events.CHANGE_SHOW_MODE, mode);
          setShowMode(mode);
        },
      }}
    >
      {children}
    </ShowContext.Provider>
  );
};

interface ShowContextContent {
  currentShow?: Show;
  setCurrentShow: (show?: Show) => void;
  showMode: CrowdScreenState['showMode'];
  setShowMode: (mode: CrowdScreenState['showMode']) => void;
}

export { ShowProvider, ShowContext };
