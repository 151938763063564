import * as React from 'react';
import * as midi from '../services/Midi';

const MidiContext = React.createContext<{
  midiDevice?: MidiDevice;
  setDeviceInfo: ({ info, event, timestamp }: MidiDevice) => void;
}>({
  setDeviceInfo: () => {},
});

const MidiProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [midiDevice, setDevice] = React.useState<MidiDevice | undefined>(undefined);

  const setDeviceInfo = React.useCallback((deviceInfo: MidiDevice) => setDevice(deviceInfo), []);

  const providerValue = React.useMemo(
    () => ({
      midiDevice,
      setDeviceInfo,
    }),
    [midiDevice, setDeviceInfo],
  );

  return <MidiContext.Provider value={providerValue}>{children}</MidiContext.Provider>;
};

export { MidiProvider, MidiContext };

interface MidiDevice {
  info?: midi.MidiTarget;
  event?: Uint8Array;
  timestamp: number;
}

export type { MidiDevice };
