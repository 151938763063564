import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const AlertDialog: React.FC<RemoveAlertProps> = ({ isOpen, onClose, title, text, actions }) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent sx={{ width: '30rem' }}>
      <DialogContentText>{text}</DialogContentText>
    </DialogContent>
    <DialogActions>{actions}</DialogActions>
  </Dialog>
);

interface RemoveAlertProps {
  isOpen: boolean;
  onClose: () => void;
  title: React.ReactNode;
  text: React.ReactNode;
  actions: React.ReactNode;
}

export { AlertDialog };
