import * as React from 'react';
import { Button } from '@mui/material';
import { AlertDialog } from '../../../../components/AlertDialog';

const RemoveSceneAlert: React.FC<RemoveSceneAlertProps> = ({
  isRemoveSceneAlertOpen,
  setIsRemoveSceneAlertOpen,
  removeScene,
}) => (
  <AlertDialog
    title='Remove scene'
    text='Are you sure you want to remove this scene?'
    isOpen={isRemoveSceneAlertOpen}
    onClose={() => setIsRemoveSceneAlertOpen(false)}
    actions={
      <>
        <Button onClick={() => setIsRemoveSceneAlertOpen(false)}>Cancel</Button>
        <Button onClick={removeScene} autoFocus>
          Remove
        </Button>
      </>
    }
  />
);

interface RemoveSceneAlertProps {
  isRemoveSceneAlertOpen: boolean;
  setIsRemoveSceneAlertOpen: (val: boolean) => void;
  removeScene: () => void;
}

export { RemoveSceneAlert };
