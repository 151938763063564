import * as React from 'react';
import { Piano } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';
import { EditorPanelProps } from '.';
import { MidiConfigDialog } from './MidiConfigDialog';

const MidiButton: React.FC<
  Pick<EditorPanelProps, 'onMidiClick' | 'midiError' | 'isMidiDialogOpen' | 'closeMidiDialog'>
> = ({ onMidiClick, midiError, isMidiDialogOpen, closeMidiDialog }) => (
  <Box>
    <Tooltip placement='top' title='Add a MIDI note to trigger the scene'>
      <Button variant='contained' component='div' sx={{ gap: 2 }} onClick={onMidiClick}>
        <Piano color={midiError ? 'warning' : undefined} />
      </Button>
    </Tooltip>
    <MidiConfigDialog isOpen={isMidiDialogOpen} onClose={closeMidiDialog} />
  </Box>
);

export { MidiButton };
