import * as React from 'react';
import { Box } from '@mui/material';
import { crowdScreenDefaultTransitionTime } from '../pages/crowd/CrowdView';
import { CrowdScreenState } from '../sharedTypes';
import { Navbar } from './Navbar';

const PageContainer: React.FC<React.PropsWithChildren<{ screenState?: CrowdScreenState }>> = ({
  children,
  screenState,
}) => (
  <Box
    sx={{
      minHeight: '100vh',
      bgcolor: screenState?.colors[0].code || 'white',
      transition: `background-color ${crowdScreenDefaultTransitionTime} ease-in`,
    }}
  >
    <Navbar />
    <Box p={4} display='flex' justifyContent='center'>
      {children}
    </Box>
  </Box>
);

export { PageContainer };
