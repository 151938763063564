import * as React from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';

const EditButtons: React.FC<EditButtonsProps> = ({ onEditTitle, onRemove }) => (
  <Stack direction='row' alignItems='center' gap={2}>
    <Tooltip title='Edit title' placement='top'>
      <IconButton
        sx={{ p: 0 }}
        color='inherit'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onEditTitle();
        }}
      >
        <Edit />
      </IconButton>
    </Tooltip>
    <Tooltip title='Remove' placement='top'>
      <IconButton
        sx={{ p: 0 }}
        color='inherit'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onRemove();
        }}
      >
        <Delete />
      </IconButton>
    </Tooltip>
  </Stack>
);

interface EditButtonsProps {
  onEditTitle: () => void;
  onRemove: () => void;
}

export { EditButtons };
