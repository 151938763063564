import * as React from 'react';
import { DragHandle, Edit, PlayArrow } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';

const SceneItemButtons: React.FC<SceneItemButtonsProps> = ({ onEdit, onPlay }) => (
  <Stack direction='row' alignItems='center' gap={2}>
    <Tooltip title='Edit' placement='top'>
      <IconButton
        sx={{ p: 0 }}
        color='inherit'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onEdit();
        }}
      >
        <Edit />
      </IconButton>
    </Tooltip>
    <Tooltip title='Play' placement='top'>
      <IconButton
        sx={{ p: 0 }}
        color='inherit'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onPlay();
        }}
      >
        <PlayArrow />
      </IconButton>
    </Tooltip>
    <DragHandle />
  </Stack>
);

interface SceneItemButtonsProps {
  onEdit: () => void;
  onPlay: () => void;
}

export { SceneItemButtons };
