import * as React from 'react';
import { Card, Stack } from '@mui/material';
import { Color } from '../../../../sharedTypes';
import { ColorButton } from './ColorButton';
import { ColorEditor } from './ColorEditor';
import { MidiButton } from './MidiButton';
import { RemoveButton } from './RemoveButton';
import { RemoveSceneAlert } from './RemoveSceneAlert';
import { SaveButton } from './SaveButton';

const EditorPanel: React.FC<EditorPanelProps> = ({
  midiError,
  isMidiDialogOpen,
  colors,
  isEditMode,
  saveScene,
  removeScene,
  onMidiClick,
  closeMidiDialog,
  setColorOrder,
  editColorCode,
  addColor,
  removeColor,
}) => {
  const [isRemoveSceneAlertOpen, setIsRemoveSceneAlertOpen] = React.useState(false);
  const [isColorEditorOpen, setIsColorEditorOpen] = React.useState(false);

  return (
    <Card sx={{ width: '100%', bgcolor: 'transparent', boxShadow: 'unset' }}>
      <RemoveSceneAlert
        removeScene={removeScene}
        isRemoveSceneAlertOpen={isRemoveSceneAlertOpen}
        setIsRemoveSceneAlertOpen={setIsRemoveSceneAlertOpen}
      />
      <ColorEditor
        isOpen={isColorEditorOpen}
        onClose={() => setIsColorEditorOpen(false)}
        setColorOrder={setColorOrder}
        colors={colors}
        editColorCode={editColorCode}
        addColor={addColor}
        removeColor={removeColor}
      />
      <Stack direction='row' spacing={2} alignItems='center'>
        <MidiButton
          onMidiClick={onMidiClick}
          midiError={midiError}
          isMidiDialogOpen={isMidiDialogOpen}
          closeMidiDialog={closeMidiDialog}
        />
        <ColorButton onClick={() => setIsColorEditorOpen(true)} />
        {isEditMode && <RemoveButton setIsRemoveDialogOpen={setIsRemoveSceneAlertOpen} />}
        <SaveButton saveScene={saveScene} />
      </Stack>
    </Card>
  );
};

interface EditorPanelProps {
  midiError: boolean;
  isMidiDialogOpen: boolean;
  colors: Color[];
  isEditMode?: boolean;
  saveScene: () => void;
  removeScene: () => void;
  onMidiClick: () => void;
  closeMidiDialog: () => void;
  setColorOrder: (colors: Color[]) => void;
  editColorCode: (id: string, code: string) => void;
  addColor: () => void;
  removeColor: (id: string) => void;
}

export type { EditorPanelProps };
export { EditorPanel };
