import * as React from 'react';
import { BubbleChart } from '@mui/icons-material';
import { Card, Stack, TextField, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { Show } from '../../../../sharedTypes';
import { EditButtons } from './EditButtons';

const ShowItem: React.FC<ShowItemProps> = ({ show, selectShow, onRemove, editTitle }) => {
  const [isEditTitleMode, setIsEditTitleMode] = React.useState(false);
  const [title, setTitle] = React.useState('');

  return (
    <Card key={show.id} sx={{ p: '0.5rem', cursor: 'pointer' }}>
      <Stack justifyContent='space-between' flexDirection='row' width='100%' alignItems='center'>
        {isEditTitleMode ? (
          <TextField
            label='Show title'
            placeholder={show.title}
            value={title}
            variant='standard'
            onChange={({ target: { value } }) => setTitle(value)}
            autoFocus
            onBlur={() => {
              title && editTitle(title);
              setIsEditTitleMode(false);
            }}
          />
        ) : (
          <Typography
            fontWeight='bold'
            display='inline-block'
            textOverflow='ellipsis'
            overflow='auto'
            onClick={() => selectShow(show)}
            component='div'
            flex={1}
          >
            <Stack direction='row' alignContent='center'>
              <BubbleChart />
              {capitalize(show.title)}
            </Stack>
          </Typography>
        )}
        <EditButtons onEditTitle={() => setIsEditTitleMode(true)} onRemove={onRemove} />
      </Stack>
    </Card>
  );
};

interface ShowItemProps {
  show: Show;
  selectShow: (show: Show) => void;
  onRemove: () => void;
  editTitle: (title: string) => void;
}

export { ShowItem };
