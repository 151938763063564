import { TableCell } from '@mui/material';

const Cell: React.FC<React.PropsWithChildren<{ color?: string }>> = ({ children, color }) => (
  <TableCell
    sx={{
      fontWeight: 'bold',
      textAlign: 'center',
      color,
    }}
  >
    {children}
  </TableCell>
);

export { Cell };
