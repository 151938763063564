import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Color } from '../../sharedTypes';
import { animationStyle } from './animation';

const CrowdScreen: React.FC<CrowdScreenProps> = ({ colors }) => {
  return (
    <Box sx={animationStyle(colors)} height='18rem' p={30} borderRadius='0.5rem'>
      <Typography>Phone screen</Typography>
    </Box>
  );
};

interface CrowdScreenProps {
  colors: Color[];
}

export { CrowdScreen };
