import * as React from 'react';
import { Card, Stack, Typography } from '@mui/material';

const DashboardCard: React.FC<React.PropsWithChildren & DashboardCardProps> = ({
  title,
  buttons,
  children,
  direction,
  minHeight,
  width,
}) => (
  <Card sx={{ width: width || '30%', minWidth: '20.5rem' }}>
    <Stack
      flexDirection={direction || 'column'}
      gap={10}
      p={10}
      flexGrow={1}
      textAlign='center'
      bgcolor='baseColors.grey1'
      borderRadius='0.3rem'
      height='100%'
      minHeight={minHeight || '30vh'}
    >
      <Stack direction='row' justifyContent='space-between'>
        <Typography textAlign='left' variant='h6' fontWeight='600' component='div'>
          {title}
        </Typography>
        {buttons && buttons}
      </Stack>
      {children}
    </Stack>
  </Card>
);

interface DashboardCardProps {
  title: React.ReactNode;
  buttons?: React.ReactNode;
  direction?: 'column' | 'row';
  minHeight?: string;
  width?: string;
}

export { DashboardCard };
