import * as React from 'react';
import { BubbleChart } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { INIT_SCREEN_STATE } from '../../../components/CrowdScreen/consts';
import { Loader } from '../../../components/Loader';
import { PageContainer } from '../../../components/PageContainer';
import { PageContent } from '../../../components/PageContent';
import { ShowContext } from '../../../context/ShowContext';
import { useMidi } from '../../../hooks/useMidi';
import { useSocket } from '../../../hooks/useSocket';
import * as events from '../../../services/Socket';
import { CrowdScreenState } from '../../../sharedTypes';
import { CrowdScreenCard } from './CrowdScreenCard';
import { ErrorPage } from './ErrorPage';
import { MidiCard } from './MidiCard';
import { ScenesCard } from './ScenesCard';
import { useUserDashboard } from './useUserDashboard';

const UserDashboard: React.FC = () => {
  const { currentShow, setCurrentShow, setShowMode } = React.useContext(ShowContext);
  const showId = currentShow?.id;
  const {
    lastActiveShow,
    lastActiveShowError,
    isLoading,
    createShow,
    saveScenesOrder,
    createLockedDevice,
    removeLockedDevice,
    findShowModeByMidiEvent,
    findSceneByMidiEvent,
  } = useUserDashboard(showId);
  const { emit } = useSocket();
  const { device, event, lockedDevice } = useMidi();
  const [crowdScreenState, setCrowdScreenState] = React.useState<CrowdScreenState>({
    ...INIT_SCREEN_STATE,
    colors: currentShow?.scenes?.[0]?.colors || INIT_SCREEN_STATE.colors,
  });

  React.useEffect(() => {
    if (!lastActiveShowError && !isLoading && !currentShow && !lastActiveShow) {
      // consider to move this case to be under "not found error" when we get show.
      createShow.mutate(defaultShowTitle);
      return;
    }
    lastActiveShow && setCurrentShow(lastActiveShow);
  }, [lastActiveShow, createShow, currentShow, lastActiveShowError, isLoading, setCurrentShow]);

  React.useEffect(() => {
    // check show mode event
    const newShowMode = findShowModeByMidiEvent(event);
    if (newShowMode) {
      setShowMode(newShowMode);
      return;
    }

    // check scene change event
    const stateColors = findSceneByMidiEvent(event, currentShow)?.colors;
    if (!stateColors) {
      return;
    }

    const newState = { ...crowdScreenState, colors: stateColors };
    setCrowdScreenState(newState);
    emit<CrowdScreenState>(events.CHANGE_SCREEN_STATE, newState);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [event.note || event.octave || event.pitch, currentShow, setShowMode]);

  if (lastActiveShowError) {
    return <ErrorPage />;
  }

  if (!currentShow?.id) {
    return (
      <PageContainer>
        <PageContent>
          <Loader />
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageContent>
        <Typography variant='h4' fontWeight='bold' alignSelf='center' mb='1.5rem'>
          <BubbleChart />
          {capitalize(currentShow.title)}
        </Typography>
        <Stack flexDirection='row' flexWrap='wrap' minHeight='30vh' gap={8} justifyContent='center'>
          <ScenesCard
            scenes={currentShow.scenes}
            saveSceneOrder={(scenes) => {
              if (!showId) return;
              saveScenesOrder.mutate({ sceneIds: scenes.map((scene) => scene.id), showId });
            }}
            playScene={(id: string) => {
              const scene = currentShow.scenes.find((sc) => sc.id === id);
              if (!scene) return;
              const newState = { ...crowdScreenState, colors: scene.colors };
              setCrowdScreenState(newState);
              emit<CrowdScreenState>(events.CHANGE_SCREEN_STATE, newState);
            }}
            saveShow={createShow.mutate}
          />
          <CrowdScreenCard colors={crowdScreenState.colors} />
          <MidiCard
            device={device}
            event={event}
            lockedDevice={lockedDevice?.name}
            lockDevice={() => {
              if (!showId || !device?.name) return;
              createLockedDevice.mutate({ showId, name: device?.name });
            }}
            unlockDevice={() => {
              if (!lockedDevice?.id) return;
              removeLockedDevice.mutate(lockedDevice?.id);
            }}
          />
        </Stack>
      </PageContent>
    </PageContainer>
  );
};

const defaultShowTitle = 'New Show';

export { UserDashboard };
