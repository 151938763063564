/**
 * This file is centered all the query keys and URLs.
 **/
const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3030/api' : '/api';

const AUTH = {
  login: `${baseURL}/auth/login`,
  loggedInSessionUser: {
    key: ['loggedInSessionUser'] as const,
    url: `${baseURL}/auth/logged-in-user`,
  },
  logout: `${baseURL}/auth/logout`,
};

const SHOW = {
  all: { key: ['shows'] as const, url: `${baseURL}/show` },
  single: (showId: string, isLastActive?: boolean) => ({
    key: [...SHOW.all.key, `${showId || 0}-lastActive:${Boolean(isLastActive)}`] as const,
    url: `${baseURL}/show/${showId || 0}${isLastActive ? '?lastActive=true' : ''}`,
  }),
  create: { url: `${baseURL}/show` },
  remove: { url: (showId: string) => `${baseURL}/show/${showId}` },
  update: { url: (showId: string) => `${baseURL}/show/${showId}` },
};

const SCENE = {
  all: (showId: string) => ({ key: ['scenes'] as const, url: `${baseURL}/scene/${showId}` }),
  single: (showId: string, sceneId: string) => ({
    key: [...SCENE.all(showId).key, sceneId] as const,
    url: `${baseURL}/scene/${showId}/${sceneId}`,
  }),
  create: { url: `${baseURL}/scene` },
  remove: { url: (sceneId: string) => `${baseURL}/scene/${sceneId}` },
  update: { url: (sceneId: string) => `${baseURL}/scene/update/${sceneId}` },
  order: { url: (showId: string) => `${baseURL}/scene/order/${showId}` },
};

const LOCKED_DEVICE = {
  all: { key: ['locked-device'] as const },
  single: (showId: string) => ({
    key: [...LOCKED_DEVICE.all.key, showId] as const,
    url: `${baseURL}/locked-device/${showId}`,
  }),
  create: { url: `${baseURL}/locked-device` },
  remove: { url: (lockedDeviceId: string) => `${baseURL}/locked-device/${lockedDeviceId}` },
};

export { AUTH, SHOW, SCENE, LOCKED_DEVICE };
