import * as React from 'react';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Cell } from '../../../components/Cell';
import { DeviceMidiEvent } from '../../../services/Midi';

const SceneMidiDetails: React.FC<{ midiDetails?: DeviceMidiEvent }> = ({ midiDetails }) => {
  if (!midiDetails) return null;

  return (
    <TableContainer component={Paper} sx={{ mb: 20, width: '50%' }}>
      <Table>
        <TableHead>
          <TableRow>
            <Cell>Note</Cell>
            <Cell>Octave</Cell>
            <Cell>Pitch</Cell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <Cell>{midiDetails.note}</Cell>
            <Cell>{midiDetails.octave}</Cell>
            <Cell>{midiDetails.pitch}</Cell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { SceneMidiDetails };
