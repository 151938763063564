import * as Router from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import * as MuiStyles from '@mui/material/styles';
import { AppRoutes } from '../AppRoutes';
import { AuthQueryClientProvider } from '../context/AuthQueryClientProvider';
import { MidiProvider } from '../context/MidiContext';
import { RefreshAlertProvider } from '../context/RefreshAlertContext';
import { ShowProvider } from '../context/ShowContext';
import { SnackbarProvider } from '../context/SnackbarContext';
import { UserProvider } from '../context/UserContext';
import * as themes from '../themes';

const App = () => {
  const theme = MuiStyles.createTheme(themes.Light);

  return (
    <MidiProvider>
      <MuiStyles.ThemeProvider theme={theme}>
        <CssBaseline />
        <Router.BrowserRouter>
          <AuthQueryClientProvider>
            <UserProvider>
              <RefreshAlertProvider>
                <SnackbarProvider>
                  <ShowProvider>
                    <AppRoutes />
                  </ShowProvider>
                </SnackbarProvider>
              </RefreshAlertProvider>
            </UserProvider>
          </AuthQueryClientProvider>
        </Router.BrowserRouter>
      </MuiStyles.ThemeProvider>
    </MidiProvider>
  );
};

export { App };
