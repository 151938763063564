import * as React from 'react';
import { Save } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';
import { EditorPanelProps } from '.';

const SaveButton: React.FC<Pick<EditorPanelProps, 'saveScene'>> = ({ saveScene }) => (
  <Box>
    <Tooltip title='Save scene' placement='top'>
      <Button variant='contained' component='div' sx={{ gap: 2 }} onClick={saveScene}>
        <Save />
      </Button>
    </Tooltip>
  </Box>
);

export { SaveButton };
