import * as React from 'react';
import { Delete } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';

const RemoveButton: React.FC<{ setIsRemoveDialogOpen: (val: boolean) => void }> = ({
  setIsRemoveDialogOpen,
}) => (
  <Box>
    <Tooltip title='Remove scene' placement='top'>
      <Button
        variant='contained'
        component='div'
        sx={{ gap: 2 }}
        onClick={() => setIsRemoveDialogOpen(true)}
      >
        <Delete />
      </Button>
    </Tooltip>
  </Box>
);

export { RemoveButton };
