import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Card } from '@mui/material';

const DraggableListItemContainer: React.FC<
  React.PropsWithChildren<DragabbleListItemContainerProps>
> = ({ draggableId, itemIndex, children }) => {
  return (
    <Draggable draggableId={draggableId} index={itemIndex}>
      {(provided) => (
        <Card
          sx={{ p: '0.5rem' }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {children}
        </Card>
      )}
    </Draggable>
  );
};

interface DragabbleListItemContainerProps {
  draggableId: string;
  itemIndex: number;
}

export { DraggableListItemContainer };
