import * as React from 'react';
import { AddBox } from '@mui/icons-material';
import { Button, Stack, Tooltip } from '@mui/material';

const AddNew: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Tooltip title='Add a new scene'>
    <Button variant='contained' onClick={onClick}>
      <Stack gap={2} direction='row'>
        <AddBox />
        Add new
      </Stack>
    </Button>
  </Tooltip>
);
export { AddNew };
