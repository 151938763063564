import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toolbar, AppBar, Stack } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import * as queries from '../../Queries';
import { ShowContext } from '../../context/ShowContext';
import { UserContext } from '../../context/UserContext';
import { useMidi } from '../../hooks/useMidi';
import { useSnackbar } from '../../hooks/useSnackbar';
import { UserRoutes } from '../../routes';
import { Show } from '../../sharedTypes';
import { AppLogo } from './AppLogo';
import { DialogMyShows } from './DialogMyShows';
import { NavigationButtons } from './NavigationButtons';
import { SettingsMenu } from './SettingsMenu';
import { ShowModeController } from './ShowModeController';

const Navbar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { loggedInUser } = React.useContext(UserContext);
  const { currentShow, setCurrentShow, showMode, setShowMode } = React.useContext(ShowContext);
  const { showSnackbar } = useSnackbar();
  const { connectedDeviceCount } = useMidi();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isAllShowsDialogOpen, setIsAllShowsDialogOpen] = React.useState(false);

  const isNavigationViewEnabled = Boolean(
    location.pathname.includes('app') && location.pathname !== UserRoutes.LOGIN && loggedInUser?.id,
  );

  const {
    data: shows,
    isLoading,
    error,
  } = useQuery<Show[]>(
    queries.SHOW.all.key,
    () => axios(queries.SHOW.all.url).then((res) => res.data),
    { enabled: isNavigationViewEnabled },
  );

  const invalidateShowQuery = async () => {
    await queryClient.invalidateQueries(queries.SHOW.single(currentShow?.id || '', true).key);
    await queryClient.invalidateQueries(queries.SHOW.all.key);
  };

  const createShow = useMutation(
    (title: string) => axios.post(queries.SHOW.create.url, { title }),
    {
      onSuccess: invalidateShowQuery,
      onError: () => showSnackbar({ severity: 'error', message: 'Failed to create a new show' }),
    },
  );

  const updateShow = useMutation(
    (show: Show) => axios.put(queries.SHOW.update.url(show.id), show),
    {
      onSuccess: invalidateShowQuery,
      onError: () => showSnackbar({ severity: 'error', message: 'Failed to update show' }),
    },
  );

  const removeShow = useMutation(
    (showId: string) => axios.delete(queries.SHOW.remove.url(showId)),
    {
      onSuccess: () => {
        setCurrentShow(undefined);
        invalidateShowQuery();
      },
      onError: () => showSnackbar({ severity: 'error', message: 'Failed to remove show' }),
    },
  );

  const logout = useMutation(() => axios.post(queries.AUTH.logout), {
    onSuccess: () => navigate(UserRoutes.LOGIN),
    onError: () => {
      showSnackbar({
        severity: 'error',
        message: 'Failed to logout. Try again after reloading the page',
      });
    },
  });

  return (
    <>
      <SettingsMenu
        isOpen={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onLogout={logout.mutate}
        anchorEl={anchorEl}
        connectedDeviceCount={connectedDeviceCount}
      />
      <DialogMyShows
        shows={shows}
        isOpen={isAllShowsDialogOpen}
        close={() => setIsAllShowsDialogOpen(false)}
        selectShow={(show) => {
          if (show.id === currentShow?.id) return;
          setCurrentShow(show);
          setIsAllShowsDialogOpen(false);
        }}
        createShow={createShow.mutate}
        updateShow={updateShow.mutate}
        removeShow={removeShow.mutate}
        isLoading={isLoading}
        error={Boolean(error)}
      />
      <AppBar position='static'>
        <Toolbar>
          <Stack direction='row' flexGrow={1} alignItems='center' spacing={5}>
            <AppLogo isLoggedInRoute={Boolean(isNavigationViewEnabled)} />
          </Stack>
          {isNavigationViewEnabled && (
            <>
              <Stack
                direction='row'
                justifyContent='center'
                alignContent='center'
                spacing={1}
                flexGrow={1}
              >
                <ShowModeController changeShowMode={setShowMode} mode={showMode} />
              </Stack>
              <Stack direction='row' flexGrow={1} justifyContent='flex-end'>
                <NavigationButtons
                  openShowsDialog={() => setIsAllShowsDialogOpen(true)}
                  setAnchorEl={setAnchorEl}
                />
              </Stack>
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export { Navbar };
