import React from 'react';
import { IconButton, Tooltip } from '@mui/material';

const NavigationButton: React.FC<NavigationButtonProps> = ({ onClick, icon, title, isActive }) => (
  <Tooltip title={title}>
    <IconButton
      size='large'
      edge='start'
      sx={{
        mr: 2,
        color: isActive ? 'baseColors.orange' : 'inherit',
      }}
      onClick={onClick}
    >
      {icon}
    </IconButton>
  </Tooltip>
);

interface NavigationButtonProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  icon: React.ReactNode;
  title: string;
  isActive?: boolean;
}

export { NavigationButton };
