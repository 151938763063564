import * as React from 'react';
import * as socket from '../services/Socket';

const useSocket = (onError?: () => void) => {
  React.useEffect(() => {
    socket.setup(onError);
    socket.emit<string>(socket.JOIN_TO_SHOW, '1'); // TODO: generate show ID.
  }, [onError]);

  const on = <Tpayload,>(evName: string, handleFn: (payload: Tpayload) => void) =>
    socket.on<Tpayload>(evName, handleFn);
  const emit = <Tpayload,>(evName: string, payload: Tpayload) =>
    socket.emit<Tpayload>(evName, payload);

  return { on, emit, socket: socket.socket };
};

export { useSocket };
