import { createTheme, PaletteOptions, ThemeOptions } from '@mui/material/styles';

const baseColors = {
  main: '#000000',
  white: '#FFF',
  grey1: '#d1cec7',
  grey2: '#5c5757',
  grey3: '#a09f9f',
  black: 'black',
  blue: 'blue',
  blueBright: '#02a6ed',
  orange: '#ed6c02',
  orangeDark: '#c55a01',
  red: 'red',
};

declare module '@mui/material/styles' {
  interface Palette {
    baseColors: {
      main: string;
      white: string;
      grey1: string;
      grey2: string;
      grey3: string;
      black: string;
      blueBright: string;
    };
  }

  interface PaletteOptions {
    baseColors: {
      main: string;
      white: string;
      grey1: string;
      grey2: string;
      grey3: string;
      black: string;
      blueBright: string;
    };
  }
}

const defaultTheme = createTheme();
const customShadows = defaultTheme.shadows;
customShadows[1] = '0px 1px 8px rgba(0, 0, 0, 0.05)';

const commonPalette: PaletteOptions = {
  text: { primary: baseColors.main },
  baseColors,
};

const commonOpts: Partial<ThemeOptions> = {
  spacing: 2,
  shadows: defaultTheme.shadows,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none',
          },
          bgcolor: baseColors.black,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '::selection': { background: baseColors.orange, color: baseColors.white },
        },
      },
    },
  },
};

const Light: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: baseColors.main,
    },
    background: {
      default: baseColors.main,
    },
    ...commonPalette,
  },
  ...commonOpts,
};

export { Light };
