import * as React from 'react';
import { Piano } from '@mui/icons-material';
import { Box, capitalize, Stack, Tooltip, Typography } from '@mui/material';
import { DragAndDropListContainer } from '../../../../../components/DragAndDrop/DragAndDropListContainer';
import { DraggableListItemContainer } from '../../../../../components/DragAndDrop/DraggableListItemContainer';
import { Scene } from '../../../../../sharedTypes';
import { calcSceneTempo } from '../../../SceneEditor/utils';
import { SceneItemButtons } from './SceneItemButtons';

const SceneList: React.FC<SceneListProps> = ({ saveSceneOrder, scenes, editScene, playScene }) => (
  <DragAndDropListContainer saveOrderedItems={saveSceneOrder} items={scenes}>
    {scenes.map((scene, idx) => {
      return (
        <DraggableListItemContainer key={scene.id} draggableId={scene.id} itemIndex={idx}>
          <Stack
            justifyContent='space-between'
            flexDirection='row'
            width='100%'
            alignItems='center'
          >
            <Stack spacing={5} direction='row' maxWidth='70%' alignItems='center'>
              <Tooltip
                placement='top'
                title={
                  <Stack direction='row' gap={1}>
                    {scene.midiEvents[0].note}
                    {scene.midiEvents[0].octave}
                    <Piano sx={{ fontSize: '1rem' }} />
                  </Stack>
                }
              >
                <Box
                  borderRadius='0.3rem'
                  height='2.2rem'
                  width='3rem'
                  p={5}
                  // consider showing an animated preview when there are multiple colors.
                  // (like at crowd screen component)
                  bgcolor={scene.colors[0].code}
                  display='flex'
                  fontSize='0.8rem'
                  fontWeight='bold'
                >
                  <Typography width='3rem' variant='inherit'>
                    {scene.midiEvents[0].note}
                    {scene.midiEvents[0].octave}
                  </Typography>
                </Box>
              </Tooltip>
              <Box
                borderRadius='0.3rem'
                height='2.2rem'
                width='3rem'
                p={5}
                // consider showing an animated preview when there are multiple colors.
                // (like at crowd screen component)
                bgcolor='baseColors.grey3'
                color='white'
                display='flex'
                fontSize='0.8rem'
                fontWeight='bold'
              >
                <Tooltip placement='top' title={`${calcSceneTempo(scene.colors)} BPM`}>
                  <Typography width='3rem' variant='inherit'>
                    {calcSceneTempo(scene.colors)}
                  </Typography>
                </Tooltip>
              </Box>
              <Tooltip title={capitalize(scene.title)} placement='top'>
                <Typography
                  fontWeight='bold'
                  display='inline-block'
                  textOverflow='ellipsis'
                  overflow='hidden'
                  noWrap
                >
                  {capitalize(scene.title)}
                </Typography>
              </Tooltip>
            </Stack>
            <SceneItemButtons
              onEdit={() => editScene(scene.id)}
              onPlay={() => playScene(scene.id)}
            />
          </Stack>
        </DraggableListItemContainer>
      );
    })}
  </DragAndDropListContainer>
);

interface SceneListProps {
  saveSceneOrder: (scene: Scene[]) => void;
  editScene: (sceneId: string) => void;
  playScene: (sceneId: string) => void;
  scenes: Scene[];
}

export { SceneList };
