import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dashboard, FormatListBulleted, MovieFilter, Settings } from '@mui/icons-material';
import { UserRoutes } from '../../routes';
import { NavigationButton } from './NavigationButton';

const NavigationButtons: React.FC<{
  openShowsDialog: () => void;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}> = ({ openShowsDialog, setAnchorEl }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = (route: string) => location.pathname === route;

  return (
    <>
      <NavigationButton
        onClick={() => navigate(UserRoutes.BASE)}
        icon={<Dashboard />}
        title='Dashboard'
        isActive={isActive(UserRoutes.BASE)}
      />
      <NavigationButton
        onClick={() => navigate(UserRoutes.SCENE_EDITOR.base)}
        icon={<MovieFilter />}
        title='Create scene'
        isActive={isActive(UserRoutes.SCENE_EDITOR.base)}
      />
      <NavigationButton onClick={openShowsDialog} icon={<FormatListBulleted />} title='My shows' />
      <NavigationButton
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        icon={<Settings />}
        title='Settings'
      />
    </>
  );
};

export { NavigationButtons };
