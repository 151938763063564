import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { noop } from 'lodash';
import * as queries from '../Queries';
import { isAuthRequired } from './AuthQueryClientProvider';

const UserContext = React.createContext<{
  loggedInUser?: User;
  setLoggedInUser: (user: User) => void;
  clear: () => void;
}>({
  loggedInUser: undefined,
  setLoggedInUser: noop,
  clear: noop,
});

const UserProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const [user, setUser] = React.useState<User | undefined>(undefined);

  const logout = useMutation(() => axios.post(queries.AUTH.logout), {
    onSuccess: () => window.location.reload(),
  });

  // ensure there is loggedInUser in context,
  // if the server considers this client session as logged in.
  useQuery<{ loggedInUser: User }>(
    queries.AUTH.loggedInSessionUser.key,
    () => axios(queries.AUTH.loggedInSessionUser.url).then((res) => res.data || {}),
    {
      onSuccess: (res) => setUser(res.loggedInUser),
      onError: () => logout.mutate(),
      // prevent of caching the logged in user,
      // to be able to always have an up to date auth state.
      cacheTime: 0,
      enabled: Boolean(!user?.id) && isAuthRequired(location.pathname),
    },
  );

  return (
    <UserContext.Provider
      value={{
        loggedInUser: user,
        setLoggedInUser: (user) => {
          if (!user?.id) {
            return;
          }

          setUser(user);
        },
        clear: () => setUser(undefined),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

interface User {
  id: string;
  name: string;
  email: string;
}

export type { User };
export { UserProvider, UserContext };
