import * as React from 'react';
import { CircularProgress, Stack } from '@mui/material';

const Loader: React.FC = () => (
  <Stack alignItems='center'>
    <CircularProgress />
  </Stack>
);

export { Loader };
