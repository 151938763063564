import { sumBy } from 'lodash';
import { Color } from '../../sharedTypes';

const animationStyle = (colors: Color[]) => {
  // prevent of doing animation when there's only 1 color in scene
  if (colors.length < 2) {
    return {
      bgcolor: colors[0].code,
    };
  }

  // animationName should be unique,
  // to be able to trigger CSS animation change.
  const animationName = 'background-animation' + Date.now();

  // ANIMATION ISSUE:
  return {
    animationName,
    animationDuration: `${sumBy(colors, (color) => color.duration)}s`,
    animationIterationCount: 'infinite',
    [`@keyframes ${animationName}`]: generateKeyframes(colors),
    // start from first color instead of defult fade in.
    // note: it causes the first color duration to be a little bit longer.
    bgcolor: colors[0].code,
  };
};

// generateKeyframes output example =>
// {
//   "33%": {
//       "bgcolor": "#41667a"
//   },
//   "66%": {
//       "bgcolor": "#31fecd"
//   },
//   "99%": {
//       "bgcolor": "#1ea640"
//   }
// }
const generateKeyframes = (colors: Color[]): KeyframeBackground => {
  let currKeyframePercentage = 0;

  return colors.reduce<KeyframeBackground>((acc, color) => {
    currKeyframePercentage += color.durationPercentage;
    acc[`${currKeyframePercentage}%`] = { bgcolor: color.code };
    return acc;
  }, {});
};

interface KeyframeBackground {
  [x: string]: {
    bgcolor: string;
  };
}

const blinkerEffect = {
  animation: 'blinker 1s linear infinite',
  '@keyframes blinker': {
    '50%': {
      opacity: 0.5,
    },
  },
};

export { animationStyle, generateKeyframes, blinkerEffect };
