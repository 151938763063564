import { noop } from 'lodash';
import { LockedDevice } from '../sharedTypes';

const getAccess = async () => navigator.requestMIDIAccess();

const listenToInput = (
  midiAccess: WebMidi.MIDIAccess,
  cb: (e: WebMidi.MIDIMessageEvent) => void,
  lockedDevice?: LockedDevice,
) => {
  if (!lockedDevice) {
    // listen to all available devices
    midiAccess.inputs.forEach((entry) => {
      entry.onmidimessage = cb;
    });
    return;
  }

  // listen only to locked device
  midiAccess.inputs.forEach((entry) => {
    if (entry.name?.trim() !== lockedDevice.name.trim()) {
      // remove listener from other devices.
      entry.onmidimessage = noop;
      return;
    }

    entry.onmidimessage = cb;
  });
};

const stopListenToInput = (midiAccess: WebMidi.MIDIAccess) => {
  midiAccess.inputs.forEach((entry) => (entry.onmidimessage = noop));
};

type MidiTarget = EventTarget & {
  state: string;
  type: string;
  connection: string;
  name: string;
};

interface DeviceMidiEvent {
  pitch?: number;
  octave?: number;
  note?: string;
  velocity?: number;
  timestamp?: number;
}

export type { MidiTarget, DeviceMidiEvent };
export { getAccess, listenToInput, stopListenToInput };
