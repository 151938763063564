import * as React from 'react';
import { Facebook, Instagram } from '@mui/icons-material';
import { Box, IconButton, Stack, SvgIcon, Typography } from '@mui/material';

const GoodBye: React.FC = () => {
  return (
    <Stack justifyContent='center' alignItems='center' textAlign='center' p={5}>
      <Typography
        variant='h3'
        color='white'
        mb={10}
        sx={{
          direction: 'rtl',
        }}
      >
        תודה רבה!
      </Typography>
      <Typography
        variant='h4'
        color='white'
        mb='1rem'
        sx={{
          direction: 'rtl',
        }}
      >
        הייתם קהל נהדר 🚀
      </Typography>
      <Box
        component='img'
        mb='2rem'
        sx={{
          width: '100%',
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
        }}
        src={require('../../media/coldplay-logo.jpeg')}
      />
      <SocialMedia />
    </Stack>
  );
};

const SocialMedia: React.FC = () => {
  return (
    <Stack spacing={5}>
      <Typography
        variant='h6'
        color='white'
        sx={{
          direction: 'rtl',
        }}
      >
        אל תשכחו לעקוב אחרינו!
      </Typography>
      <Stack direction='row' justifyContent='center' spacing={10}>
        <IconButton>
          <a target='_blank' rel='noreferrer' href='https://www.facebook.com/xandycoldplay/'>
            <SvgIcon sx={{ fontSize: '3rem', color: 'baseColors.white' }}>
              <Facebook />
            </SvgIcon>
          </a>
        </IconButton>
        <IconButton>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://instagram.com/israel_coldplay?igshid=NTc4MTIwNjQ2YQ=='
          >
            <SvgIcon sx={{ fontSize: '3rem', color: 'baseColors.white' }}>
              <Instagram />
            </SvgIcon>
          </a>
        </IconButton>
      </Stack>
    </Stack>
  );
};

export { GoodBye };
