import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import * as queries from '../../../Queries';
import { MIDI_NOTE_SHOW_MODE } from '../../../components/Navbar/ShowModeController';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { DeviceMidiEvent } from '../../../services/Midi';
import { Scene, Show } from '../../../sharedTypes';

const useUserDashboard = (showId?: string) => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const {
    data: lastActiveShow,
    isLoading: isLastActiveShowLoading,
    error: lastActiveShowError,
  } = useQuery<Show>(queries.SHOW.single(showId || '', Boolean(!showId)).key, () =>
    axios(queries.SHOW.single(showId || '', Boolean(!showId)).url).then((res) => res.data),
  );

  const createShow = useMutation(
    (title: string) => axios.post(queries.SHOW.create.url, { title }),
    {
      onSuccess: () => {
        showSnackbar({ severity: 'success', message: 'Show successfully created' });
        queryClient.invalidateQueries(queries.SHOW.all.key);
      },
      onError: () => showSnackbar({ severity: 'error', message: 'Failed to create a new show' }),
    },
  );

  const createLockedDevice = useMutation(
    (data: { showId: string; name: string }) => axios.post(queries.LOCKED_DEVICE.create.url, data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queries.LOCKED_DEVICE.single(showId || '').key),
      onError: () =>
        showSnackbar({
          severity: 'error',
          message: 'Failed to lock device. Please Try again or later',
        }),
    },
  );

  const removeLockedDevice = useMutation(
    (lockedDeviceId: string) => axios.delete(queries.LOCKED_DEVICE.remove.url(lockedDeviceId)),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queries.LOCKED_DEVICE.single(showId || '').key),
      onError: () =>
        showSnackbar({
          severity: 'error',
          message: 'Failed to unlock device. Please try again or later',
        }),
    },
  );

  const saveScenesOrder = useMutation(
    (data: { sceneIds: Array<Scene['id']>; showId: Show['id'] }) =>
      axios.put(queries.SCENE.order.url(data.showId), { sceneIds: data.sceneIds }),
    {
      onSuccess: () => showId && queryClient.invalidateQueries(queries.SHOW.single(showId).key),
      onError: () =>
        showSnackbar({
          severity: 'error',
          message: 'Failed to reorder scenes. Please try again or later',
        }),
    },
  );

  return {
    lastActiveShow,
    lastActiveShowError,
    createShow,
    createLockedDevice,
    removeLockedDevice,
    saveScenesOrder,
    isLoading:
      isLastActiveShowLoading ||
      createShow.isLoading ||
      createLockedDevice.isLoading ||
      removeLockedDevice.isLoading ||
      saveScenesOrder.isLoading,
    findSceneByMidiEvent,
    findShowModeByMidiEvent,
  };
};

const findSceneByMidiEvent = (currentEvent: DeviceMidiEvent, currentShow?: Show) => {
  if (!currentShow?.scenes?.length) return;
  return currentShow.scenes?.find(({ midiEvents }) => {
    return (
      midiEvents?.[0].note === currentEvent.note &&
      midiEvents?.[0].octave === currentEvent.octave &&
      midiEvents?.[0].pitch === currentEvent.pitch
    );
  });
};

const findShowModeByMidiEvent = (midiEvent: DeviceMidiEvent) => {
  if (midiEvent.octave !== 0) {
    return;
  }

  return midiEvent.note ? MIDI_NOTE_SHOW_MODE[midiEvent.note] : undefined;
};

export { useUserDashboard };
