import * as React from 'react';
import { Button, Card, Dialog, Stack, TextField, Typography } from '@mui/material';

const DialogCreateShow: React.FC<{
  isOpen: boolean;
  close: () => void;
  save: (title: string) => void;
}> = ({ isOpen, close, save }) => {
  const [title, setTitle] = React.useState('');
  const [err, setErr] = React.useState<{ title?: string } | undefined>(undefined);
  // * every state section should be added to clearState() as well.

  const clearState = () => {
    setErr(undefined);
    setTitle('');
  };

  const closeDialog = () => {
    clearState();
    close();
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <Card sx={{ p: 10, minHeight: '15rem', minWidth: '30rem' }}>
        <Stack spacing={15} maxWidth='100%'>
          <Typography variant='h5'>Create new show</Typography>
          <Stack height='10rem' justifyContent='space-between'>
            <TextField
              label='Show title'
              placeholder='Show title...'
              sx={{ maxWidth: '20rem' }}
              value={title}
              variant='standard'
              onChange={({ target: { value } }) => setTitle(value)}
              autoFocus
              error={Boolean(err?.title)}
              helperText={err?.title && 'Scene title is missing'}
            />
            <Stack direction='row' spacing={5}>
              <Button
                variant='contained'
                sx={{ minWidth: '5rem' }}
                onClick={() => {
                  if (!title) {
                    setErr({ title: 'Please type a show title' });
                    return;
                  }
                  clearState();
                  save(title);
                }}
              >
                Save
              </Button>
              <Button variant='outlined' sx={{ minWidth: '5rem' }} onClick={closeDialog}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Dialog>
  );
};

export { DialogCreateShow };
