import * as React from 'react';
import { AddBox } from '@mui/icons-material';
import { Button, Dialog, Stack, Tooltip } from '@mui/material';
import { DashboardCard } from '../../../../../components/DashboardCard';
import { Color } from '../../../../../sharedTypes';
import { ColorList } from './ColorList';

const ColorEditor: React.FC<ColorEditorProps> = ({
  isOpen,
  onClose,
  colors,
  setColorOrder,
  editColorCode,
  addColor,
  removeColor,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DashboardCard
        title='Colors'
        buttons={
          <Stack direction='row' justifyContent='flex-end'>
            <Tooltip title='Add color' placement='top'>
              <Button sx={{ minWidth: 'unset' }} onClick={addColor}>
                <AddBox />
              </Button>
            </Tooltip>
          </Stack>
        }
      >
        <ColorList
          colors={colors}
          setColorOrder={setColorOrder}
          editColorCode={editColorCode}
          removeColor={removeColor}
        />
      </DashboardCard>
    </Dialog>
  );
};

interface ColorEditorProps {
  isOpen: boolean;
  onClose: () => void;
  colors: Color[];
  setColorOrder: (colors: Color[]) => void;
  editColorCode: (id: string, code: string) => void;
  addColor: () => void;
  removeColor: (id: string) => void;
}

export { ColorEditor };
