import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { PageContainer } from '../../../components/PageContainer';
import { PageContent } from '../../../components/PageContent';

const ErrorPage: React.FC = () => {
  const reload = () => location.reload();

  return (
    <PageContainer>
      <PageContent>
        <Stack alignItems='center' justifyContent='center' height='100%'>
          <Typography variant='h6'>
            Something went wrong 🫠 <Box component='br' />
            please try to
            <Box
              component='span'
              onClick={reload}
              sx={{ cursor: 'pointer' }}
              color='baseColors.blueBright'
            >
              {' '}
              reload{' '}
            </Box>
            the page.
          </Typography>
        </Stack>
      </PageContent>
    </PageContainer>
  );
};

export { ErrorPage };
