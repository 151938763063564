import * as React from 'react';
// Compiled/transpiled async functions
import { createRoot } from 'react-dom/client';
import 'core-js/stable';
// Includes polyfills for ECMAScript up to 2021
import 'regenerator-runtime/runtime';
import { App } from './components/App';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
