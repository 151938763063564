import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { CrowdRoutes, UserRoutes } from '../routes';
import { UserContext } from './UserContext';

const AuthQueryClientProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { clear: clearUserContext } = React.useContext(UserContext);

  const handleUnauthorizedError = () => {
    if (!isAuthRequired(location.pathname)) {
      return;
    }

    // the client has no permission to visit this route.
    // since in our app it means he doesn't logged in,
    // we redirect him to the login page.

    clearUserContext();
    navigate(UserRoutes.LOGIN);
  };

  // withCredentials indicates whether or not cross-site Access-Control requests
  // should be made using credentials.
  axios.defaults.withCredentials = true;

  // intercept the authorization errors.
  // since ReactQuery defaultOptions.onError overrides the onError func globally,
  // using axios interceptors to handles this case.
  axios.interceptors.response.use(
    (res) => {
      const isUnauthorizedErr = res.status === 401;
      if (isUnauthorizedErr) {
        handleUnauthorizedError();
      }

      return res;
    },
    (err: AxiosError) => {
      const isUnauthorizedErr = err.response?.status === 401;
      if (isUnauthorizedErr) {
        handleUnauthorizedError();
      }

      return err;
    },
  );

  const queryClient = new QueryClient();
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

// ROUTES_WITHOUT_AUTH represents route path names that don't require authentication.
// (client can visit those routes without beeing logged in).
const ROUTES_WITHOUT_AUTH = [CrowdRoutes.BASE, UserRoutes.LOGIN];

/**
 * isAuthRequired returns true whether the current pathName reuires authentication (the user should be logged in).
 * @param pathName string
 * @returns boolean
 */
const isAuthRequired = (pathName: string) => {
  return ROUTES_WITHOUT_AUTH.every((route) => route !== pathName);
};

export { isAuthRequired, AuthQueryClientProvider };
