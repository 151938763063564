import * as React from 'react';

const useKeepAwake = () => {
  React.useEffect(() => {
    keepAwake();
  }, []);
};

// keepAwake keeps the crowd screen on.
const keepAwake = () => {
  if (!window.isSecureContext || !navigator.wakeLock) {
    // available only in secure connection (https)
    return;
  }

  navigator.wakeLock.request('screen').catch((error) => {
    console.log('keep awake error', error);
  });
};

export { useKeepAwake };
