import * as React from 'react';
import { Sync } from '@mui/icons-material';
import { Dialog, IconButton, Stack, SvgIcon, Typography } from '@mui/material';

const RefreshAlertContext = React.createContext<{ showRefreshAlert: () => void }>({
  showRefreshAlert: () => undefined,
});

const RefreshAlertProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <RefreshAlertContext.Provider
      value={{
        showRefreshAlert: () => {
          setIsOpen(true);
        },
      }}
    >
      <Dialog
        open={isOpen}
        PaperProps={{ sx: { background: 'transparent' } }}
        onClose={() => location.reload()}
        sx={{ p: 5 }}
      >
        <Stack>
          <Stack textAlign='center'>
            <Typography variant='h5' fontWeight='bold' color='baseColors.white'>
              You're out of Sync 🫠
            </Typography>
            <Typography color='baseColors.white'>Push to return the show!</Typography>
          </Stack>
          <IconButton onClick={() => location.reload()}>
            <SvgIcon
              sx={{
                fontSize: '10rem',
                color: 'baseColors.white',
                bgcolor: 'baseColors.blueBright',
                borderRadius: '50%',
                p: 5,
              }}
            >
              <Sync />
            </SvgIcon>
          </IconButton>
        </Stack>
      </Dialog>
      {children}
    </RefreshAlertContext.Provider>
  );
};

export { RefreshAlertProvider, RefreshAlertContext };
