import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Avatar, Divider, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { UserContext } from '../../context/UserContext';
import { UserRoutes } from '../../routes';

const SettingsMenu: React.FC<SettingsMenuProps> = ({ isOpen, onClose, onLogout, anchorEl }) => {
  const { loggedInUser } = React.useContext(UserContext);
  const navigate = useNavigate();

  if (!anchorEl) return null;
  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      PaperProps={{ elevation: 0 }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
    >
      <MenuItem sx={{ gap: 5 }}>
        <Avatar sx={{ width: '2rem', height: '2rem' }} />
        <Typography display='inlin'>{capitalize(loggedInUser?.name || 'Welcome')}</Typography>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => navigate(UserRoutes.BASE)}>
        <ListItemIcon>
          <DashboardIcon fontSize='small' />
        </ListItemIcon>
        Dashboard
      </MenuItem>
      <MenuItem onClick={onLogout}>
        <ListItemIcon>
          <Logout fontSize='small' />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );
};

interface SettingsMenuProps {
  isOpen: boolean;
  connectedDeviceCount: number;
  onClose: () => void;
  onLogout: () => void;
  anchorEl: HTMLElement | null;
}

export { SettingsMenu };
