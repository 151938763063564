import * as React from 'react';
import { ColorLens, Delete, DragHandle } from '@mui/icons-material';
import { IconButton, Input, InputLabel, Stack, Tooltip } from '@mui/material';

const ColorItemButtons: React.FC<ColorItemButtonsProps> = ({
  onChangeColor,
  onRemove,
  isMoreThanOneColor,
}) => {
  return (
    <Stack direction='row' alignItems='center' gap={2}>
      <Tooltip title='Edit color' placement='top'>
        <InputLabel>
          {/* Button component must be 'div' to be able to open the color picker on click */}
          <IconButton component='div' sx={{ minWidth: 'unset', p: 0, color: 'baseColors.black' }}>
            <ColorLens />
          </IconButton>
          <Input
            type='color'
            sx={{ visibility: 'hidden' }}
            disableUnderline
            onChange={({ target: { value } }) => onChangeColor(value)}
          />
        </InputLabel>
      </Tooltip>
      {isMoreThanOneColor && (
        <>
          <Tooltip title='Remove' placement='top'>
            <IconButton
              sx={{ p: 0 }}
              color='inherit'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onRemove();
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </>
      )}
      <DragHandle />
    </Stack>
  );
};

interface ColorItemButtonsProps {
  onChangeColor: (newColor: string) => void;
  onRemove: () => void;
  isMoreThanOneColor: boolean;
}

export { ColorItemButtons };
