import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import * as queries from '../Queries';
import { MidiContext } from '../context/MidiContext';
import { ShowContext } from '../context/ShowContext';
import { logError } from '../services/Logger';
import * as midi from '../services/Midi';
import { LockedDevice } from '../sharedTypes';

const useMidi = () => {
  const midiContext = React.useContext(MidiContext);
  const showContext = React.useContext(ShowContext);
  const [access, setAccess] = React.useState<WebMidi.MIDIAccess | undefined>(undefined);
  const [error, setError] = React.useState('');

  const device = midiContext.midiDevice?.info;
  const showId = showContext.currentShow?.id;

  const { data: lockedDevice } = useQuery<LockedDevice>(
    queries.LOCKED_DEVICE.single(showId || '').key,
    () => axios(queries.LOCKED_DEVICE.single(showId || '').url).then((res) => res.data),
    { enabled: Boolean(showId) },
  );

  const getAccessInfo = () => {
    midi
      .getAccess()
      .then(setAccess)
      .catch((err) => {
        setError(err);
        logError(`MIDI getAccessInfo error: ${err}`);
      });
  };

  React.useEffect(() => {
    if (!access) {
      getAccessInfo();
      return;
    }
    setError('');
  }, [access]);

  const setDeviceInfo = React.useCallback(
    (event?: WebMidi.MIDIMessageEvent) => {
      if (!event) {
        return;
      }

      const device = event.currentTarget as midi.MidiTarget;

      if (device.name) {
        midiContext.setDeviceInfo({
          info: device,
          event: event.data,
          timestamp: Date.now(),
        });
      }

      return event;
    },
    [midiContext],
  );

  const listen = React.useCallback(
    (handleEvent: (e: WebMidi.MIDIMessageEvent) => void) => {
      if (!access) {
        setError('no access');
        return error;
      }

      midi.listenToInput(access, handleEvent, lockedDevice);
    },
    [access, error, lockedDevice],
  );

  const stopListen = React.useCallback(() => {
    access && midi.stopListenToInput(access);
  }, [access]);

  React.useEffect(() => {
    stopListen();
    listen(setDeviceInfo);
  }, [lockedDevice, stopListen, listen, setDeviceInfo]);

  const getEventInfo = (): midi.DeviceMidiEvent => {
    if (!midiContext.midiDevice?.event) return {};

    const { event, timestamp } = midiContext.midiDevice;

    const pitch = event[1];
    const velocity = event[2];
    const octave = Math.floor(pitch / 12 - 1);
    const notes = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
    const note = notes[pitch % 12];
    return {
      pitch,
      octave,
      note,
      velocity,
      timestamp,
    };
  };

  return {
    connectedDeviceCount: access?.inputs.size || 0,
    device,
    event: getEventInfo(),
    error,
    lockedDevice,
    access,
  };
};

export { useMidi };
