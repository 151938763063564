import * as React from 'react';
import { Box } from '@mui/material';

const PageContent: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Box
    display='flex'
    justifyContent='center'
    flexDirection='column'
    width='100%'
    height='100%'
    p={10}
    maxWidth='1680px'
    gap={2}
  >
    {children}
  </Box>
);

export { PageContent };
