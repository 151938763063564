import { sumBy, uniqueId } from 'lodash';
import { DeviceMidiEvent } from '../../../services/Midi';
import { Color } from '../../../sharedTypes';

// getDefualtColor returns a default color with last color order and fake id.
const getRandomColor = (colors?: Color[]): Color => {
  const defaultDurationSec = 0.5;
  const defaultPercentage = 100; // should be calced and update all colors together

  return {
    id: uniqueId(FAKE_ID_PREFIX),
    code: getRandomColorCode(),
    durationPercentage: defaultPercentage,
    duration: defaultDurationSec,
    order: colors ? colors.length + 1 : 1, // next color.
  };
};
const getRandomColorCode = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

// recalcColorsDurationAndPercentage calcs and edits each color duration and duration percentage,
// based on the colors count and the scene tempo.
// note: the color percentage and duration is needed for setting CSS keyframes.
const recalcColorsDurationAndPercentage = (colors: Color[], sceneTempo: number) => {
  const newColors = [...colors];
  // each color duration is equal, and derived from the scene tempo.
  const oneColorDurationSeconds = 60 / sceneTempo;
  newColors.forEach((c) => (c.duration = oneColorDurationSeconds));

  // calc each color percentage duration.
  const totalSceneDurationSec = sumBy(newColors, (color) => color.duration);
  newColors.forEach((color) => {
    return (color.durationPercentage = Math.round((color.duration / totalSceneDurationSec) * 100));
  });

  return newColors;
};

const calcSceneTempo = (colors: Color[]) => {
  const sceneTempo = sumBy(colors, (color) => 60 / color.duration) / colors.length;
  return Math.round(sceneTempo * 10) / 10;
};

// isValidDeviceMidiEvent returns true if all the event fields are defined.
const isValidDeviceMidiEvent = (midiEvent?: DeviceMidiEvent): boolean => {
  return Boolean(midiEvent && Object.values(midiEvent).every((val) => Boolean(val)));
};

const PARAMS_ID = 'id';
const FAKE_ID_PREFIX = 'fake-id';

export {
  getRandomColor,
  recalcColorsDurationAndPercentage,
  calcSceneTempo,
  isValidDeviceMidiEvent,
  PARAMS_ID,
  FAKE_ID_PREFIX,
};
